// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the theme object.
$a-theme: mat.define-theme(
	(
		color: (
			theme-type: light,
			primary: mat.$azure-palette,
			tertiary: mat.$blue-palette
		),
		density: (
			scale: 0
		)
	)
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
:root {
	@include mat.all-component-themes($a-theme);
}

// Comment out the line below if you want to use the pre-defined typography utility classes.
// For more information: https://material.angular.io/guide/typography#using-typography-styles-in-your-application.
// @include mat.typography-hierarchy($a-theme);

// Comment out the line below if you want to use the deprecated `color` inputs.
// @include mat.color-variants-backwards-compatibility($a-theme);
// Comment out the line below if you want to use the pre-defined typography utility classes.
// For more information: https://material.angular.io/guide/typography#using-typography-styles-in-your-application.
// @include mat.typography-hierarchy($a-theme);

// Comment out the line below if you want to use the deprecated `color` inputs.
// @include mat.color-variants-backwards-compatibility($a-theme);

@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';
button:disabled {
	opacity: 0.5;
}
#popup-terminos .p-dialog br {
	margin-bottom: 8px !important;
}
#popup-terminos .p-dialog .p-dialog-header {
	color: #ec6726 !important;
	font-size: 22px;
	@apply font-ubuntu;
}

#popup-terminos .p-dialog p {
	color: #000 !important;
	font-size: 13px;
	@apply font-ubuntu;
	margin-bottom: 8px;
}
#popup-terminos .p-dialog ol {
	padding: 0 0 0 20px;
}
#popup-terminos .p-dialog ol > li {
	color: #000 !important;
	font-size: 13px;
	@apply font-ubuntu;
	margin-bottom: 8px;
}
#popup-terminos .p-dialog {
	background: #fff !important;
}
input:disabled,
select:disabled {
	background-color: #f0f0f0 !important; /* Gris claro */
}
.p-accordioncontent-content {
	padding: 0 9px 0 0 !important;
}
.p-accordion .p-accordioncontent {
	border: 0 !important;
}
.p-accordionheader {
	padding: 0 5px 0 0 !important;
}
.p-accordionpanel {
	border: 1px solid #e5e7eb !important;
	border-radius: 5px !important;
	padding-top: 9px !important;
	padding-left: 9px !important;
	padding-bottom: 9px !important;
}

#fecchas_paso_1 .p-datepicker-input-icon-container {
	top: 62% !important;
}
#fecchas_paso_1 > * {
	flex: 0 0 calc(50% - 4px);
}
#fecchas_paso_1 .p-datepicker input {
	border: 0 !important;
	box-shadow: none;
	padding: 18px 0 8px 0 !important;
	height: 40px !important;
	font-size: 14px;
}
.p-toast-message-error {
	background-color: color-mix(in srgb, var(--p-red-50), transparent 5%) !important;
}
.direccion-texto {
	font-size: 15px;
}
#detalle_horarios p:nth-child(3) {
	margin-top: 5px;
}
#detalle_horarios p > strong {
	font-weight: 500;
}
#detalle_horarios p {
	font-size: 15px;
}
.ooeriru {
	font-size: 13pt !important;
	font-weight: bold !important;
	@apply font-ubuntu;
}
.p-skeleton {
	background: #e2e8f0 !important;
}
.p-toast-message-warn .p-toast-detail,
.p-toast-message-error .p-toast-detail {
	color: #000 !important;
}
.p-toast-message-warn {
	background-color: color-mix(in srgb, var(--p-yellow-50), transparent 5%) !important;
	color: #ca8a04 !important;
	border-color: #fef08a !important;
}

#buscador- .p-inputtext {
	color: #000 !important;
	font-size: 14px;
}
.responsive-div-banner > div {
	height: calc(864px * (100vw / 1920px)) !important; /* Ajusta según el ancho base */
}
.responsive-div-promociones > div {
	height: calc(550px * (100vw / 1920px)) !important; /* Ajusta según el ancho base */
}
.responsive-div-destinos > div {
	height: 320px !important; /* Ajusta según el ancho base */
}

#detallek {
	z-index: 2;
}
.btni {
	display: block;
	border: 1px solid;
	border-radius: 5px;
	padding: 4px;
	text-transform: uppercase;
	line-height: 110%;
	font-size: 12px;
	letter-spacing: 0.5px;
}
.asientoSeleccionado .precioo > p {
	color: #fff !important;
}
.precioo > p {
	transition: all 0.3s;
}
.siEsAsiento:hover .precioo > p {
	color: #fff !important;
}
@media (prefers-color-scheme: dark) {
	:root {
		color-scheme: light !important;
	}
}
input:focus {
	background: #fff !important;
}
select,
input {
	background: #fff !important;
}
.p-drawer .p-inputtext {
	color: #000 !important;
	border: 1px solid #e5e7eb !important;
}
.p-inputtext:enabled:focus {
	border: 1px solid #e5e7eb !important;
	color: #000 !important;
}
.p-inputtext:enabled:hover {
	border: 1px solid #e5e7eb !important;
}
.p-inputtext.p-variant-filled {
	background: #fff !important;
}
.p-drawer {
	background: #fff !important;
	color: #334155 !important;
	border: 1px solid #e2e8f0 !important;
}
.dark * {
	all: unset !important;
}
.dark * {
	--tw-bg-opacity: 1;
	background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
	color: black !important;
}
::-webkit-scrollbar-thumb {
	background: #ccc;
}

.dark-mode ::-webkit-scrollbar-track {
	background: #fff !important; /* Fondo más oscuro */
}

::-webkit-scrollbar-track {
	background: #f1f1f1 !important;
}
.p-select-option.p-select-option-selected.p-focus,
.p-select-option.p-select-option-selected {
	background: #e5e7eb !important;
	color: #000 !important;
}
.p-select-overlay {
	background: #fff !important;
	border: 1px solid #e5e7eb !important;
}
body {
	background: #fff;
}
.p-toast-close-button.p-button-text {
	color: #000 !important;
}

#descipcion_destino_ a,
#atractivos_turisticos_sliders a,
#fag_div_contenido a,
#contenido-paginas a,
#servicio_div_contenido a {
	text-decoration: underline !important;
}
.d_jjkd {
	z-index: 1;
}
.box-formularios select {
	/* Arrow */
	appearance: none;
	background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23131313%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
	background-repeat: no-repeat;
	background-position: right 0.7rem top 50%;
	background-size: 0.65rem auto;
}

.p-select-list {
	padding: 0 !important;
	margin: 0 !important;
}
.box_cupon-- ul {
	padding: 0 !important;
	margin: 0 !important;
}
.box_cupon-- ul > li {
	list-style: none !important;
}
#default-modal a:hover {
	text-decoration: none;
}
#default-modal a {
	transition: all 0.3s;
	@apply underline;
}
.faq_ul {
	padding: 0 !important;
}
.faq_ul > li {
	list-style: none !important;
}
.box_main ol,
.box_main ul {
	padding: 0 0 0 15px;
	@apply mb-3;
}
.box_main ul li {
	@apply list-disc;
}
.box_main ol li {
	@apply list-decimal font-ubuntu text-f16 font-normal text-black;
}

#detalle_destino_calendario {
	position: relative;
	z-index: 1;
}
.banner_corporativo {
	width: 100%;
	height: calc(90vh - 72px);
	background-position: center center;
	background-size: cover;
}
.custom-galleria .p-galleria-item {
	transition: opacity 1s ease-in-out;
}
#detalle_destino_descripcion {
	margin-top: 40px;
}
.custom-galleria .p-galleria-item-hidden {
	opacity: 0;
}

.custom-galleria .p-galleria-item-active {
	opacity: 1;
}

@keyframes fadeEffect {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
.p-carousel-content-container .p-button-text:not(:disabled):hover {
	color: #ec6726;
}
.p-carousel-content-container .p-button-text {
	color: #fff;
}
.numeros-paginador.active {
	background-color: #4d4d4d !important;
}
.p-drawer .p-inputicon {
	top: 3px !important;
}
.p-drawer .p-inputtext {
	height: 30px !important;
	padding: 0 5px !important;
	border: 1px solid #e5e7eb !important;
}
.p-drawer .p-iftalabel label {
	font-size: 15px;
	margin-left: 9px;
	margin-top: 9px;
}
.p-drawer .p-iftalabel {
	border: 1px solid #e5e7eb;
	border-radius: 5px;
	margin-bottom: 10px;
	padding: 10px 6px 5px 9px;
}
.desktop.totototo .p-select-label {
	color: #fff !important;
}
#buscador .p-select-overlay .p-inputicon {
	top: 0;
}
#buscador .p-select-overlay .p-select-header {
	padding-left: 3px !important;
	padding-right: 3px !important;
}
#buscador .p-select-overlay .p-inputtext {
	padding-top: 0;
	border: 1px solid #ddd !important;
	font-size: 13px;
	padding: 3px !important;
}
.p-select {
	border: transparent !important;
}
.p-select:not(.p-disabled).p-focus {
	border-color: transparent !important;
}
.p-select {
	background: transparent !important;
	box-shadow: none !important;
}
.p-select-label {
	color: #000 !important;
	font-size: 13px;
	font-family: 'ubuntu' !important;
	padding-left: 0 !important;
	padding-bottom: 0 !important;
	padding-top: 15px !important;
}
.p-select-list li {
	color: #000;
	font-size: 13px;
	font-family: 'ubuntu' !important;
	@apply font-ubuntu;
}
.localidadesOrigen {
	padding-left: 0;
}
.p-iftalabel label {
	top: 0px !important;
}
.desktop.totototo .p-inputtext::placeholder,
.desktop.totototo .p-iconwrapper {
	color: #fff;
}
.desktop.totototo .p-floatlabel label,
.desktop.totototo .p-iftalabel label {
	color: #fff !important;
}
.p-datepicker-input-icon-container {
	top: 70% !important;
}
#buscador- .p-inputtext {
	border: none !important;
	box-shadow: none;
	background: transparent !important;
	padding-bottom: 0;
	padding-top: 15px;
}
.p-floatlabel:has(input:focus) label,
.p-floatlabel:has(input:-webkit-autofill) label,
.p-floatlabel:has(textarea:focus) label,
.p-floatlabel:has(.p-inputwrapper-focus) label {
	color: #000 !important;
	@apply font-ubuntu text-f14;
}
.p-floatlabel label,
.p-iftalabel label {
	left: 0 !important;
	color: #000 !important ;
	@apply font-ubuntu text-f14;
}
.p-inputtext {
	padding-left: 0 !important;
	padding-right: 0 !important;
}
.p-datepicker-year-selected {
	background: #ec6726 !important;
}
.p-inputtext:enabled:focus {
	border-color: transparent !important;
}
.p-datepicker-today > .p-datepicker-day-selected,
.p-datepicker-day-selected {
	background: #ec6726 !important;
}
.p-slider-handle:focus-visible {
	outline: 1px solid #ec6726 !important;
}
.p-slider .p-slider-range {
	background: #ec6726 !important;
}

.lineadd {
	height: 67px;
	background: #d4d8dd;
	width: 2px;
}
#ida-vuelta- .mat-expansion-panel-header:hover {
	background-color: transparent;
}
#ida-vuelta- .mat-expansion-panel-header {
	height: 40px;
}
#ida-vuelta- {
	border: 1px solid #d4d8dd;
	border-radius: 20px !important;
	padding: 0 15px;
	margin-bottom: 20px;
}
#ida-vuelta- .mat-expansion-panel-header .mat-expansion-indicator {
	margin-right: 6px !important;
}
#ida-vuelta- .mat-expansion-panel-body {
	padding: 0;
}

.totototo .ng-select .ng-select-container .ng-value-container .ng-placeholder {
	color: #fff;
}
.totototo .ng-select .ng-arrow-wrapper .ng-arrow {
	border-color: #fff rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) !important;
}
.text-selecionado {
	color: white !important;
}
.origen .ng-dropdown-panel,
.destino .ng-dropdown-panel {
	width: auto;
}
.origen .ng-select.ng-select-opened > .ng-select-container,
.destino .ng-select.ng-select-opened > .ng-select-container {
	background: transparent;
}
.origen .ng-select-container,
.destino .ng-select-container {
	border: 0;
	background: transparent;
}
.origen .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input,
.destino .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
	padding-left: 0;
}
.origen .ng-select .ng-select-container .ng-value-container,
.destino .ng-select .ng-select-container .ng-value-container {
	padding-left: 0;
	padding-top: 16px;
}
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
	top: 11px;
}
.ng-select .ng-clear-wrapper {
	display: none;
}
// .origen ng-input > input {
// 	height: 45px;
// 	padding-top: 10px;
// }
.origen .ng-placeholder.ng-star-inserted {
	margin-top: 3px;
}
.origen .ng-value.ng-star-inserted {
	margin-top: 3px;
}
.origen .ng-select span,
.destino .ng-select span {
	@apply font-ubuntu text-f13 text-black;
}

ng-select-searchable
	ng-select-clearable
	ng-select
	ng-select-single
	ng-select-searchable
	ng-select-clearable
	ng-select
	ng-select-single
	ng-select-bottom
	ng-select-opened
	html {
	scroll-behavior: smooth;
}

.slider-popups .owl-dots {
	position: absolute;
	bottom: 0;
	width: 100%;
}
#box_listado_destinos img.fondo {
	height: 300px;
}
.cabeceras_height_1 {
	min-height: 400px;
}
.cabeceras_height_2 {
	min-height: 200px;
}

#default-modal ol {
	@apply list-decimal px-5;
}
#default-modal ol li {
	@apply font-lato font-normal text-black text-f14 mb-2;
}
.w-porcentaje_1 {
	width: 12.5%;
}
#buscador {
	bottom: 50px;
	z-index: 9;
}
#busacador- select {
}

.placeholder {
	color: #999999 !important;
}

.busacador-banner select option {
	color: #000;
}

.banner_principal {
	position: relative;
}
.banner_principal .owl-prev {
	background-image: url('/images/left_1.png') !important;
	height: 40px;
	width: 40px;
	background-repeat: no-repeat;
	background-color: transparent !important;
	margin: 0 !important;
	padding: 0 !important;
	z-index: 2;
}
.banner_principal .owl-next {
	background-image: url('/images/right_1.png') !important;
	height: 40px;
	width: 40px;
	background-repeat: no-repeat;
	background-color: transparent !important;
	margin: 0 !important;
	padding: 0 !important;
}
.banner_principal .owl-nav {
	position: absolute;
	top: 0;
	right: 100px;
	top: calc(50% - 48px);
	display: flex;
	flex-direction: column-reverse;
	margin: 0;
}
.banner_principal .owl-nav [class*='owl-'] {
	display: block;
}
.banner_principal .owl-nav > div:first-child {
	margin-bottom: 0 !important;
}
.banner_principal .owl-nav > div {
	margin-bottom: 17px !important;
}
.banner_principal .owl-dots {
	position: absolute;
	bottom: 172px;
	width: 100%;
}
.banner_principal .owl-dots .owl-dot.active {
	border: 2px solid #fff;
	border-radius: 50%;
	height: 26px;
	width: 26px;
}
.banner_principal .owl-dots .owl-dot {
	border: 2px solid transparent;
	border-radius: 50%;
	height: 26px;
	width: 26px;
}
.banner_principal .owl-dots .owl-dot.active span {
	background: #fff;
}
.banner_principal .owl-dots .owl-dot span:hover {
	background: #4d4d4d;
}
.banner_principal .owl-dots .owl-dot span {
	background: #fff;
	height: 12px;
	width: 12px;
	margin: 5px 5px;
	transition: all 0.2;
}
.descuentos-especiales-carousel {
	padding: 0 15px;
}
.sliders {
	position: relative;
}
.sliders .owl-prev {
	background-image: url('/images/left_2.png') !important;
	height: 40px;
	width: 40px;
	background-repeat: no-repeat;
	background-color: transparent !important;
	margin: 0 !important;
	padding: 0 !important;
	z-index: 2;
}
.sliders .owl-next {
	background-image: url('/images/right_2.png') !important;
	height: 40px;
	width: 40px;
	background-repeat: no-repeat;
	background-color: transparent !important;
	margin: 0 !important;
	padding: 0 !important;
}
.sliders .owl-nav {
	position: absolute;
	top: -70px;
	right: 0;
	display: block;
	flex-direction: column-reverse;
	margin: 0;
}
.sliders .owl-nav > div {
	margin-left: 20px !important;
}

.range-input {
	position: relative;
}

.multi-range-slider {
	position: relative;
	width: 100%;
}
.range-values {
	display: flex;
	justify-content: space-between;
	margin-top: 20px;
}
.multi-range-slider {
	margin-top: 30px;
}

.lugares_bg {
	height: 500px;
	background-repeat: no-repeat;
	background-size: cover;
	width: 100%;
	background-position: center center;
}
.festivos_bg .overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 50%;
	height: 100%;
	background-color: #ec6726;
	clip-path: polygon(0 0, 100% 0, 80% 100%, 0% 100%);
}
.festivos_bg {
	height: 500px;
	background-repeat: no-repeat;
	background-size: cover;
	width: 100%;
	background-position: right center;
	position: relative;
}

.banner_v1 .owl-prev {
	background-image: url('/images/left_1.png') !important;
	height: 40px;
	width: 40px;
	background-repeat: no-repeat;
	background-color: transparent !important;
	margin: 0 !important;
	padding: 0 !important;
	z-index: 2;
}
.banner_v1 .owl-next {
	background-image: url('/images/right_1.png') !important;
	height: 40px;
	width: 40px;
	background-repeat: no-repeat;
	background-color: transparent !important;
	margin: 0 !important;
	padding: 0 !important;
}
.banner_v1 .owl-nav {
	position: absolute;
	top: 0;
	right: 100px;
	top: calc(50% - 48px);
	display: flex;
	flex-direction: column-reverse;
	margin: 0;
}
.banner_v1 .owl-nav > div:first-child {
	margin-bottom: 0 !important;
}
.banner_v1 .owl-nav > div {
	margin-bottom: 17px !important;
}
.tono_naranja {
	width: 10px;
	height: 55px;
	background-color: #ec6726;
}
.contenido_interna_servicio p {
	@apply font-ubuntu text-f16 font-normal text-black mb-3;
}
.bg-active {
	background-color: #4d4d4d !important;
}
.parrafos-faq p {
	@apply font-lato text-black mb-3;
}
.faq_ul li {
	@apply mb-3;
	cursor: pointer;
}
.contenido_paginas ol li,
.contenido_paginas p {
	@apply font-lato text-black text-f15 font-normal list-decimal mb-4 text-justify;
}
#buscador- select {
	-webkit-appearance: none;
	outline: none;
	background-image: url(/images/icon_select.png);
	background-position: calc(100% - 0px) calc(1em + 12px), calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
	background-repeat: no-repeat;
	@apply w-full border-0 pt-4 pb-1 rounded-md font-ubuntu text-f17;
}
.totototo #buscador- {
	@apply bg-[#EC6726];
	border-radius: 0;
	max-width: 100%;
}
.totototo select,
.totototo [type='date'] {
	@apply bg-transparent text-white;
}
.totototo h2,
.totototo label {
	@apply text-white;
}
.totototo button.botton_form {
	top: 0 !important;
	@apply bg-white text-[#EC6726] border-white hover:bg-[#4d4d4d] hover:border-[#4d4d4d] hover:text-white;
}
.totototo {
	@apply shadow-none;
}
.ztick_ > .container-nav {
	@apply px-[50px] py-2;
}
.menu_canvas li a {
	@apply font-ubuntu font-normal text-f16 text-[#000] hover:text-[#EC6726] transition-all hover:no-underline block relative py-1;
}
.menu_canvas li {
	@apply mb-1 relative;
}
.menu_canvas li:hover::before {
	background-color: #ec6726;
}
.menu_canvas li:before {
	height: 30px;
	width: 8px;
	content: '';
	position: absolute;
	top: 1px;
	left: -17px;
	transition: all 0.3s;
}
.lines-items {
	position: relative;
	margin-top: 15px;
}
.lines-items::before {
	position: absolute;
	background-color: #ec6726;
	height: 45px;
	width: 2px;
	position: absolute;
	content: '';
	left: 0;
	top: 0;
}
.lines-items::after {
	position: absolute;
	background-color: #ec6726;
	height: 20px;
	width: 20px;
	position: absolute;
	content: '';
	left: -9px;
	top: 30px;
	border-radius: 50%;
}
.flex-boxed-50 {
	flex: 0 0 calc(50% - 40px);
}
#buscador.totototo {
	@apply fixed w-full;
	top: 56px;
	max-width: 100%;
	border-radius: 0;
	bottom: inherit;
}
#buscador.totototo #buscador- {
	@apply shadow-none px-[10px] py-[10px];
}
#buscador.totototo {
	background-color: #ec6726;
}
#mega-menu-full li.active a {
	color: #ec6726;
}
#buscador.desktop {
	bottom: 50px;
	position: absolute;
	@apply w-full;
}
#buscador.desktop.totototo {
	bottom: inherit;
	position: fixed;
}
#buscador.mobile {
	z-index: 99;
	background-color: transparent;
	bottom: inherit;
	position: fixed;
	top: 50px;
	padding: 0;
}
#buscador.mobile #buscador- {
	@apply rounded-b-[20px];
	background-color: #fff;
}
#buscador.mobile #buscador- > div {
	padding-bottom: 10px;
	padding-top: 10px;
	padding: 10px 10px;
	border-bottom: 1px #d9d9d9 solid !important;
}
#buscador.mobile .origen,
#buscador.mobile .f_salida {
	border-right: 1px #d9d9d9 solid !important;
}
#buscador.mobile label,
#buscador.mobile select,
#buscador.mobile [type='date'] {
	@apply text-black;
}
#buscador.mobile #buscador- {
	padding: 0 0 30px 0;
}
#buscador.mobile .mat-expansion-panel-header {
	background-color: #ec6726;
	padding: 10px 0;
}
#buscador.mobile #buscador- {
	@apply grid-cols-2 divide-none;
}
#buscador.mobile .mat-expansion-panel {
	box-shadow: none !important;
}
#buscador.mobile .mat-content {
	flex: none;
}
#buscador.mobile .mat-expansion-panel-header {
	@apply justify-center;
}
#buscador.mobile .mat-expansion-panel-header-title {
	@apply font-ubuntu text-[#fff] font-bold text-f17;
}
#buscador.mobile #buscador- {
	display: flex;
	flex-wrap: wrap;
}
#buscador.mobile .origen,
#buscador.mobile .destino,
#buscador.mobile .f_salida,
#buscador.mobile .f_retorno {
	width: 50%;
}
#buscador.mobile .n_pasajeros {
	width: 100%;
}
#buscador.mobile .botton_form {
	width: 250px;
	margin: auto;
	background: #ec6726;
	color: #fff;
	margin-top: 25px;
}
#buscador.mobile .botton_form:hover {
	background: #4d4d4d;
}
.box_main {
	@apply pt-[70px];
}
.owl-stage {
	padding-left: 0px !important;
}
.encomiendas__bg {
	background-repeat: no-repeat;
	background-size: cover;
	width: 100%;
	height: 600px;
}
.encomiendas__box__contenido h3 {
	@apply font-ubuntu font-light text-white text-f22;
	line-height: 240%;
}
.encomiendas__box__contenido h3 span {
	@apply font-ubuntu font-bold text-white text-f45;
	line-height: 80%;
}
.encomiendas__box__contenido p {
	@apply font-ubuntu font-light text-white text-f16;
}

.encomiendas__box__contenido.negro h3 {
	@apply text-black;
	line-height: 210%;
}
.encomiendas__box__contenido.negro h3 span {
	@apply text-black;
}
.encomiendas__box__contenido.negro p {
	@apply text-black text-f22;
}
#cabecera-internas {
	position: relative;
}
#cabecera-internas #buscador.desktop,
#bannes_internas_servicios #buscador.desktop {
	bottom: -50px;
	top: inherit;
}
#cabecera-internas #buscador.desktop.totototo,
#bannes_internas_servicios #buscador.desktop.totototo {
	top: 65px !important;
	bottom: inherit !important;
}
#promociones_listado {
	margin-top: 70px;
}
#bannes_internas_servicios .owl-dots {
	bottom: 70px;
}
#listado-destinos {
	// margin-top: 40px;
}
.separador-izquieda::before {
	content: '';
	position: absolute;
	left: 0;
	top: 7px;
	width: 10px;
	height: 40px;
	background-color: #ec6726;
}
.separador-izquieda {
	padding: 0 0 0 34px;
}
#slider-politicas-viaje img {
	width: auto;
}
.faq_ul summary {
	padding-right: 40px;
}
#page-contato {
	margin-top: 80px;
}

// ECOMMERCE
.process-flow {
	display: flex;
	justify-content: space-between;
	width: 100%;
	max-width: 800px;
	padding: 20px;
}
.step {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 22%;
}
.circle {
	width: 50px;
	height: 50px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 24px;
	font-weight: bold;
	margin-bottom: 10px;
}
.step.active .circle {
	background-color: #ec6726;
	color: white;
	border: 2px solid #ec6726;
}
.step .circle {
	border: 2px solid black;
	color: black;
}
.step .circle:hover {
	@apply bg-[#EC6726] border-[#EC6726] border-[2px] text-white;
}
.step-text {
	text-align: center;
	font-size: 14px;
	line-height: 1.2;
}
#calendario > button {
	width: 20%;
}
#acodion-registro-pasajeros .mat-expansion-panel {
	@apply p-4 border-[#D4D8DD] border-[1px] shadow-none mt-6 rounded-[10px];
}
#acodion-registro-pasajeros .mat-expansion-panel .mat-expansion-panel-header-title {
	@apply font-ubuntu text-black text-f20 font-bold;
}
#acodion-registro-pasajeros .mat-expansion-panel,
#acodion-registro-pasajeros .mat-expansion-panel-body {
	padding: 0;
}
#acodion-registro-pasajeros .mat-expansion-panel-header {
	padding: 10px 30px;
}
#acodion-listado-pagos .mat-expansion-panel-spacing {
	margin: 0;
}
#acodion-listado-pagos > .mat-expansion-panel:last-child {
	@apply border-b-[#D4D8DD] border-b-[1px];
}
#acodion-listado-pagos > .mat-expansion-panel {
	@apply px-4 border-t-[#D4D8DD] border-l-[#D4D8DD] border-r-[#D4D8DD] border-t-[1px] border-r-[1px] border-l-[1px] shadow-none rounded-none;
}
#acodion-listado-pagos .mat-expansion-panel .mat-expansion-panel-header-title {
	@apply font-lato text-black text-f16 font-normal;
}
#acodion-listado-pagoss .mat-expansion-panel,
#acodion-listado-pagos .mat-expansion-panel-body {
	padding: 0;
}
#acodion-listado-pagos .mat-expansion-panel-header {
	padding: 10px 15px 15px 5px;
}

.toggles-container {
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.toggle-wrapper {
	display: flex;
	align-items: center;
	gap: 10px;
}

.toggle-label {
	position: relative;
	display: inline-block;
	width: 36px;
	height: 20px;
	background-color: #d1d5db;
	border-radius: 16px;
	cursor: pointer;
	transition: background-color 0.3s ease;
}

.toggle-button {
	position: absolute;
	top: 2px;
	left: 2px;
	width: 15px;
	height: 15px;
	background-color: white;
	border-radius: 50%;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
	transition: transform 0.3s ease;
}

.toggle-checkbox {
	display: none;
}

.toggle-checkbox:checked + .toggle-label {
	background-color: #f97316;
}

.toggle-checkbox:checked + .toggle-label .toggle-button {
	transform: translateX(16px);
}

.toggle-text {
	font-size: 16px;
	color: #4b5563;
}

.fade-in {
	opacity: 0;
	animation: fadeIn 0.2s forwards;
}

.fade-out {
	opacity: 1;
	animation: fadeOut 0.2s forwards;
}
#buscador.totototo.desktop #buscador- {
	max-width: 1250px;
}
#buscador.totototo.desktop #buscador- #seleccionar-pasajero {
	@apply bg-transparent text-white;
}
#btn-clic-open-box-buscador-mobile {
	background-color: #ec6726 !important;
	color: #fff !important;
}
#dropdownContentBucadorMobile .n_pasajeros label,
#dropdownContentBucadorMobile .n_pasajeros label:hover {
	color: #000 !important;
}
#dropdownContentBucadorMobile- #seleccionar-pasajero:hover {
	background-color: transparent !important;
}
#dropdownContentBucadorMobile #seleccionar-pasajero {
	background-color: transparent !important;
	color: #000 !important;
}
// .totototo .boton-pasajeros {
// 	@apply bg-transparent text-white hover:bg-transparent;
// }
.visible_ {
	visibility: visible;
	opacity: 1;
	max-height: 1000px;
}

.hidden_ {
	visibility: hidden;
	opacity: 0;
	max-height: 0;
}
#buscador.totototo {
	z-index: 9;
}
#buscador {
	z-index: 6;
}
@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes fadeOut {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}
// ECOMMERCE
#caption-caption {
	@apply absolute top-0 bottom-0 z-[6] flex justify-center items-center px-6;
}
#caption-caption .inner_ h1 span {
	@apply text-[60px];
}
#caption-caption .inner_ h1 {
	@apply font-ubuntu font-bold text-white text-f45 leading-[120%];
}
#caption-caption .inner_ p {
	@apply font-ubuntu font-light text-white text-f25 mt-[10px];
}
#features-landings > div {
	margin-bottom: 50px;
}
#boxes_fondo img.fondo {
	@apply object-cover h-[600px] w-full;
}
.box-destino_ img.fondo {
	@apply object-cover h-[320px] w-full;
}
#linea-de-tiempo .owl-stage {
	display: flex;
}
#linea-de-tiempo .lines-items {
	position: absolute;
	bottom: 15px;
	height: 45px;
}
#linea-de-tiempo .owl-stage > div {
	padding-bottom: 30px;
}
#contenedor_linea {
	position: relative;
}
#contenedor_linea::after {
	background: #ec6726;
	width: 100%;
	height: 2px;
	content: '';
	position: absolute;
	bottom: 58px;
	left: 0;
}
#contenedor_linea .owl-carousel {
	display: flex !important;
	flex-direction: row;
	justify-content: left;
}
#contenedor_line .owl-stage {
	padding-left: 0px !important;
}
#hs-offcanvas-right .active a {
	color: #ec6726;
}
#hs-offcanvas-right li.active::before {
	background-color: #ec6726;
}
#contenido-paginas h2 {
	@apply relative block mb-5 font-bold text-black font-ubuntu text-f45 pl-[30px];
	line-height: 140%;
}
#contenido-paginas h2::before {
	content: '';
	position: absolute;
	left: 0;
	top: 15px;
	width: 10px;
	height: 55px;
	background-color: #ec6726;
}

.buscador-destinos .input {
	@apply border-[#D4D8DD] border-[1.5px] border-solid rounded-[20px] font-ubuntu text-[#909090] text-f16 pl-5 pr-[50px] h-[40px] w-full outline-0;
}
.botton__destinos {
	@apply bg-[#EC6726] h-[40px] w-[49px] text-center flex items-center justify-center rounded-r-2xl absolute top-0 right-0 transition-all hover:bg-[#4d4d4d];
}
.items__filtros {
	@apply flex items-center;
}
.checkboxes {
	@apply w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600;
}

.items__filtros label {
	@apply font-ubuntu;
}

@mixin input-range-track {
	&::-webkit-slider-runnable-track {
		@content;
	}

	&::-moz-range-track {
		@content;
	}
}

@mixin input-range-thumb {
	&::-webkit-slider-thumb {
		@content;
	}

	&::-moz-range-thumb {
		@content;
	}
}

@mixin appearance($value: auto) {
	-webkit-appearance: $value;
	-moz-appearance: $value;
	appearance: $value;
}

@mixin focus {
	@supports selector(:focus-visible) {
		&:focus-visible {
			@content;
		}
	}

	@supports not selector(:focus-visible) {
		&:focus {
			@content;
		}
	}
}
.is-invalid {
	border-color: red !important;
}

.error-message {
	color: red;
	font-size: 12px;
	margin-top: -12px;
	@apply px-5;
}

.loader {
	display: inline-block;
	width: 20px;
	height: 20px;
	border: 2px solid #ffffff;
	border-radius: 50%;
	border-top: 2px solid transparent;
	animation: spin 1s linear infinite;
}
.loader_inputs {
	display: inline-block;
	width: 20px;
	height: 20px;
	border: 2px solid #ec6726;
	border-radius: 50%;
	border-top: 2px solid transparent;
	animation: spin 1s linear infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
#cabecera-internas .fondo {
	@apply object-cover h-[350px] w-full;
}
#slider-popup .owl-dots {
	position: absolute;
	width: 100%;
	z-index: 2;
	bottom: 10px;
}
#slider-popup .owl-dot.active span {
	background-color: #4d4d4d !important;
}
#slider-popup .owl-theme .owl-dots .owl-dot span {
	height: 15px;
	width: 15px;
	background-color: #ec6726;
}
// Range slider
.box_degradado {
	height: 65%;
	position: absolute;
	bottom: 0;
	left: 0;
	z-index: 1;
	width: 100%;
	background: rgb(0, 0, 0);
	background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0) 100%);
}
.boton-filtro-responsive {
	display: none;
}
.lineadd2 {
	display: none;
}
#container-countdown {
	position: absolute;
	top: 30px;
	right: 0;
}
@media (max-width: 1199px) {
	.info-contacto-- br {
		display: none;
	}
	.sidebar_filtros--desktop {
		display: none;
	}
	.contenedor-destinos {
		@apply grid-cols-1;
	}
	.seccion-direccion p {
		text-align: center;
	}
	.footer-division {
		display: block;
	}
	.items_footer {
		margin-top: 40px;
	}
}
@media (max-width: 1023px) {
	.lineadd2 {
		height: 1px;
		background: #d4d8dd;
		width: 100%;
		margin: 0;
		display: block;
	}
	#box_detalle_mobile button {
		background: #fff;
		color: #ec6726;
		width: auto;
		font-size: 11.5px;
		padding: 0 8px;
		height: 38px;
	}
	#box_detalle_mobile {
		background: #ec6726;
	}
	.boxed_mobile {
		width: 70px;
	}
}
@media (max-width: 1199px) {
	#direccionane {
		@apply grid-cols-1 space-x-0;
	}
}

@media (max-width: 1024px) {
	.lineadd {
		display: none;
	}
	.bebeSinAsiento {
		display: none;
	}
	// .p-scrollpanel-bar-y {
	// 	opacity: 1 !important;
	// }
	#container-countdown {
		top: 120px;
		right: 0;
		width: 100%;
		display: flex;
		justify-content: end;
	}
	.link-detaller {
		border: 1px #d4d8dd solid;
		border-radius: 20px;
		height: 35px;
		padding: 0 15px;
	}

	#detallek,
	#kdjfey33 {
		background: #fff;
		z-index: 9;
	}
	#rayald {
		display: none;
	}

	#cabecera-internas {
		margin-top: 44px;
	}
	#buscador_home_mobile #buscador- > button:hover {
		border: none !important;
	}
	#buscador_home_mobile #buscador- > button {
		background-color: #ec6726 !important;
		color: #fff;
		height: 53px;
		width: calc(50% - 10px);
	}
	#buscador_home_mobile .origen,
	#buscador_home_mobile .destino,
	#buscador_home_mobile .f_salida,
	#buscador_home_mobile .f_retorno,
	#buscador_home_mobile .n_pasajeros {
		border: none;
	}
	#buscador_home_mobile .n_pasajeros #seleccionar-pasajero:hover {
		background-color: transparent;
	}
	#buscador_home_mobile .n_pasajeros #seleccionar-pasajero {
		padding-top: 0 !important;
		background-color: transparent;
		color: #334155 !important;
		height: 53px;
	}
	#buscador_home_mobile #dropdownContent {
		z-index: 9;
	}
	#buscador_home_mobile .n_pasajeros > div {
		border: 1px solid #feac30;
		border-radius: 12px;
		margin-bottom: 15px;
		padding: 0px 0px 0px 15px !important;
	}
	#buscador_home_mobile .origen > div,
	#buscador_home_mobile .destino > div,
	#buscador_home_mobile .f_salida > div,
	#buscador_home_mobile .f_retorno > div {
		border: 1px solid #feac30;
		border-radius: 12px;
		padding: 9px 0px 5px 15px !important;
		margin-bottom: 15px;
	}
	#buscador_home_mobile #buscador- .p-select-header .p-inputtext {
		border: 1px solid #000 !important;
		padding: 0 5px !important;
		height: 35px;
	}
	#buscador_home_mobile #buscador- .p-inputicon {
		top: 6px;
	}
	#buscador_home_mobile .p-datepicker:has(.p-datepicker-input-icon-container) {
		width: 100%;
	}
	#buscador_home_mobile {
		margin-top: 20px;
	}
	#buscador_home_mobile .p-select-label,
	#buscador_home_mobile .p-inputtext {
		font-size: 14px !important;
	}
	#buscador_home_mobile #buscador- > div {
		flex: 0 0 auto;
		width: 50%;
		padding: 0 10px;
	}
	#buscador_home_mobile #buscador- {
		display: flex;
		flex-wrap: wrap;
		align-items: normal;
	}
	#buscador_home_mobile #buscador- {
		padding: 0;
	}
	#buscador_home_mobile #buscador- {
		background-color: #fff;
	}
	#detalle_destino_descripcion {
		margin-top: 0px;
	}

	.boton-filtro-responsive {
		display: block;
	}
	.box-formularios {
		@apply grid-cols-[100%];
	}
	#page-contato {
		margin-top: 40px;
	}
	.contenido_paginas ul,
	.contenido_paginas ol {
		padding: 0 15px;
	}
	.contenido_paginas li {
		font-size: 14px;
	}
	.texto-servicios-- {
		padding-left: 30px;
	}
	.texto-servicios--.texto-servicios--2 {
		padding-right: 30px;
	}
	.boxing_destinos-- {
		height: 300px;
	}
	#listado-destinos {
		margin-top: 0px;
	}
	#bannes_internas_servicios {
		margin-top: 0px;
	}
	.iconos_servicios {
		@apply grid-cols-3;
	}
	#promociones_listado {
		margin-top: 0px;
	}
	.box-servicios- .bg-box_servicio {
		@apply px-5;
	}
	.banner_principal .owl-dots {
		bottom: 10px;
	}
	.box_main {
		@apply pt-[73px];
	}
	#buscador- h2 {
		display: none;
	}

	#buscador- {
		@apply bg-[#EC6726];
		border-radius: 0;
		max-width: 100%;
	}
	#buscador- select,
	#buscador- [type='date'] {
		@apply bg-transparent text-white;
	}
	#buscador- h2,
	#buscador- label {
		@apply text-white;
	}
	#buscador- button.botton_form {
		top: 0 !important;
		@apply bg-white text-[#EC6726] hover:bg-[#4d4d4d] hover:border-[#4d4d4d] hover:text-white;
	}
	#buscador- {
		@apply shadow-none;
	}
	#buscador {
		display: block;
	}
	#buscador {
		@apply fixed w-full;
		top: 92px;
		max-width: 100%;
		border-radius: 0;
		bottom: inherit;
	}
	#buscador {
		z-index: 9;
		@apply bg-[#EC6726];
	}
	#buscador .busacador-banner {
		top: 0;
		z-index: 100;
		@apply bg-transparent;
	}
	#buscador select,
	#buscador [type='date'] {
		@apply bg-transparent text-white;
	}
	#buscador h2,
	#buscador label {
		@apply text-white;
	}
	#buscador button {
		top: 0 !important;
		@apply bg-white text-[#EC6726] border-white hover:bg-[#4d4d4d] hover:border-[#4d4d4d] hover:text-white;
	}
	#buscador {
		@apply py-3 shadow-none;
	}
	#bannes_internas_servicios .owl-dots {
		bottom: 10px;
	}
	.flex-boxed-50 {
		flex: 0 0 calc(50% - 40px);
	}
}
@media (max-width: 991px) {
	.responsive-div-banner > div {
		height: calc(759px * (100vw / 991px)) !important; /* Ajusta según el ancho base */
	}

	.banner_corporativo {
		height: calc(60vh);
	}
	#promociones_listado.promociones_listado .griddi a {
		width: 250px;
	}
	#promociones_listado.promociones_listado .griddi {
		@apply grid-cols-1;
	}
	#valores {
		flex-wrap: nowrap;
		overflow: auto;
		scroll-snap-type: x mandatory;
		justify-content: flex-start !important;
		padding: 0 0 30px 0;
		@apply gap-1;
	}
	#valores > div {
		width: 300px;
		flex: 0 0 auto;
	}
	.atencion-al-cliente .dato2 {
		font-size: 25px;
	}
	.atencion-al-cliente {
		width: 370px;
	}
	.atencion-al-cliente {
		@apply grid-cols-1 justify-center;
	}
	.text-ayuda-- p {
		text-align: center;
	}
	.text-ayuda-- {
		width: 100%;
	}
	.completado-- {
		margin-top: 15px;
	}
	.completado-- p {
		text-align: center;
	}
	.completado-- h4 {
		text-align: center;
	}
	.completado-- {
		width: 100%;
	}
	.info-contacto-- {
		display: block;
	}
	.separador-- {
		display: none;
	}
	.info-contacto-- br {
		display: none;
	}
	.botton-servicio--- {
		@apply justify-center;
	}
	.texto-servicios-- {
		margin-top: 30px;
	}
	.texto-servicios-- h2,
	.texto-servicios-- p {
		text-align: center;
	}
	.imagen-servicio--- img {
		margin: auto;
	}
	.contenedor-tabs--- .servicios--- {
		display: block;
	}
	.texto-servicios-- {
		padding-left: 0;
	}
	.texto-servicios--.texto-servicios--2 {
		padding-right: 0;
	}
	.contenedor-tabs--- .servicios--- {
	}
	.iconos_servicios {
		@apply grid-cols-4;
	}
	.contenido_servicios-- h2,
	.contenido_servicios-- h1,
	.contenido_servicios-- p {
		text-align: center;
	}
	.contenido_servicios-- h1 {
		margin-bottom: 30px;
	}
	.parrafo_servicio-- {
		margin: auto auto 40px auto;
	}
	.contenido_servicios-- {
		display: block;
	}
	.iconos_servicios {
		@apply grid-cols-3;
	}
	#mega-menu-full ul {
		display: none !important;
	}
	#info_extra .separador {
		display: none;
	}
	#info_extra {
		@apply space-x-1 ml-1;
	}
	#info_extra .headertelefono a {
		@apply py-0 px-1 text-f15;
	}
	#info_extra .headertelefono {
		@apply ml-0;
	}
}
@media (max-width: 986px) {
	.responsive-div-promociones.tres {
		display: none;
	}
	.responsive-div-promociones > div {
		height: calc(488px * (100vw / 986px)) !important; /* Ajusta según el ancho base */
	}
	#promociones-skeletor {
		@apply grid-cols-2;
	}
}
@media (min-width: 768px) {
	.p-drawer-right.md\:\!w-80 {
		width: 30rem !important;
	}
}
@media (max-width: 768px) {
	.responsive-div-promociones.tres,
	.responsive-div-promociones.dos {
		display: none;
	}
	.responsive-div-promociones > div {
		height: calc(718px * (100vw / 786px)) !important; /* Ajusta según el ancho base */
	}
	#promociones-skeletor {
		@apply grid-cols-1;
	}
	.p-toast-detail {
		color: #000 !important;
	}
	.siEsAsiento.asientoSeleccionado:hover .precioo > p {
		color: #fff !important;
	}
	.siEsAsiento:hover .precioo > p {
		color: #000 !important;
	}
	.siEsAsiento:hover .precioo {
		background-color: #d4d8dd !important;
		color: #000 !important;
	}
	#cambio_fehca_anterior {
		display: none;
	}
	#cambio_fehca_siguiente {
		display: none;
	}
	#calendario > button {
		width: 33.3333%;
	}
	#boxes_fondo.servicios_home .encomiendas__botones {
		justify-content: flex-start;
	}
	#boxes_fondo.servicios_home .order-last {
		order: inherit;
	}
	#boxes_fondo.servicios_home > div {
		width: 100%;
	}
	#cabecera-internas .fondo {
		@apply h-[350px];
	}
	#nosotros {
		@apply mt-[30px];
	}
	.cabeceras_height_1 {
		min-height: 300px;
	}
	.encomiendas__bg > div {
		@apply items-end pb-[30px];
	}
	#linea-de-tiempo h2 {
		font-size: 35px;
	}
	.titulo_nosotros {
		font-size: 35px !important;
	}
	#contenedor_valores img {
		margin: auto auto auto auto;
	}
	#contenedor_valores {
		display: block;
	}
	#contenedor_valores .linea_valores > div {
		width: 100%;
		height: 1px;
		margin: 30px 0;
	}
	#contenedor_valores p {
		text-align: center;
	}
	#contenedor_valores {
		margin-top: 70px;
	}
	#contenedor_valores .linea_valores {
	}
	#boxes_fondo img.fondo {
		@apply object-cover h-[450px] w-full;
	}
	#datos-destino {
		@apply justify-center;
	}
	#info_destinoso {
		@apply grid-cols-1;
	}
	#features-landings > div {
		@apply w-[50%];
	}
	#caption-caption .inner_ h1 span {
		@apply text-f30;
	}
	#caption-caption .inner_ h1 {
		@apply font-ubuntu font-bold text-white text-f30 leading-[120%];
	}
	#caption-caption .inner_ p {
		@apply text-f15 mt-[10px];
	}
	.landingnhs img {
		@apply object-cover h-[500px] w-full;
	}
	.botonens a {
		@apply text-f14;
	}

	.box-destino-contacto {
		height: 400px;
	}
	.box-destino-contacto .encomiendas__botones {
		justify-content: left;
	}
	.box-destino-contacto h3 span {
		font-size: 60px;
	}
	.separador-izquieda {
		padding: 0 0 0 15px;
	}
	#titulov1 h2 {
		line-height: 120%;
	}
	#call-to-action-contacto h3 {
		font-size: 19px;
		line-height: 130%;
	}
	#call-to-action-contacto h3 span {
		font-size: 28px;
	}
	#slider-politicas-viaje h5,
	#slider-politicas-viaje p {
		text-align: center;
	}
	#botton-mas-destinos-- a {
		width: 200px;
	}
	.lista-destinos--- {
		@apply grid-cols-2;
	}
	.bg-box_servicio {
		padding: 15px;
	}
	.bg-box_servicio h2 span {
		font-size: 30px;
	}
	.bg-box_servicio .encomiendas__botones {
		justify-content: left;
	}
	.parrafo_servicio-- {
		@apply w-full;
	}
	#cabecera-internas > div {
		@apply items-end;
	}
	#cabecera-internas h2 {
		line-height: 200%;
		margin-bottom: 30px;
	}
	#cabecera-internas h2 span {
		font-size: 55px;
	}
	.box-servicios- .encomiendas__botones {
		justify-content: initial;
	}
	.box-servicios- {
		display: block;
	}
	.box-servicios- > div {
		margin-bottom: 30px;
	}
	.items_footer {
		@apply grid-cols-2;
		padding-left: 0;
		border-left: 0;
	}
	.seccion-logo hr {
		width: 85%;
		margin: 1.5rem auto 1.5rem auto;
	}
	.seccion-direccion p {
		text-align: center;
	}
	.seccion-logo {
		padding-right: 0 !important;
	}
	footer > div.seccion_informacion {
		padding-top: 40px !important;
		padding-bottom: 30px !important;
	}
	.logos-metodos.gap-16 {
		gap: 15px !important;
		justify-content: center;
	}
	.box-metodos p {
		text-align: center;
		margin-bottom: 15px;
	}
	.box-metodos {
		display: block;
	}
	#pasajero-frecuente {
		padding-top: 30px;
		padding-bottom: 40px;
	}
	.lista-beneficios_ {
		padding-left: 0 !important;
		margin-top: 25px;
	}
	.encomiendas__botones {
		justify-content: center;
	}
	.encomiendas__box__contenido h3 {
		line-height: 170% !important;
	}
	.encomiendas__box__contenido.negro p {
		text-align: center;
	}
	#pasajero-frecuente h3 {
		text-align: center;
	}
	.encomiendas__box__contenido h3 span {
		font-size: 35px !important;
	}
	#pasajero-frecuente h3 br {
		display: none;
	}
	#pasajero-frecuente > div {
		@apply divide-none;
		display: block;
	}
	.box-destino_ h3 {
		font-size: 17px;
	}
	.box-destino_ h3 span {
		font-size: 33px;
	}
	.box-destino_ img.fondo {
		@apply h-[350px];
	}
	.encomiendas__bg {
		height: 450px;
	}
	.encomiendas__botones a {
		font-size: 14px;
		width: 150px;
	}
	.encomiendas__box__contenido h3 {
		line-height: 200%;
	}
	.banner_principal .owl-nav {
		right: 10px;
		display: none;
	}
	.box_main {
		@apply pt-[56px];
	}
	#mega-menu-full {
		display: flex !important;
		order: 1;
		width: auto !important;
	}
	#navbar > div {
		@apply container-nav flex flex-wrap justify-between items-center mx-auto py-2 px-3 transition-all;
	}
	#contenido-paginas h2 {
		padding-left: 0;
		font-size: 30px !important;
	}
	#contenido-paginas h2::before {
		height: 0;
		width: 0;
		line-height: 120%;
	}
}

@media (max-width: 560px) {
	#popup-terminos .p-dialog .p-dialog-header > span {
		font-size: 18px !important;
		line-height: 130%;
	}
	#popup-terminos .p-dialog[pn_id_8]:not(.p-dialog-maximized) {
		width: 96vw !important;
	}
	.p-toast {
		width: 95% !important;
	}
	.nooror {
		font-size: 12px;
	}
	.cursor-pointer.btni {
		text-align: center;
		font-size: 10px;
	}
	#dropdownContent {
		max-height: 350px !important;
		overflow-y: auto;
	}
	#mega-menu-full .headertelefono a {
		padding-left: 6px !important;
		padding-right: 8px !important;
	}
	#icono_wsp_tel img {
		width: 25px;
		height: 25px;
	}
	.texto-pasajeros-desktop {
		display: none;
	}
	.process-flow {
		padding: 20px 0;
	}
	.step-text {
		font-size: 13px;
	}
	.step .circle {
		width: 40px;
		height: 40px;
		font-size: 17px;
	}
	#buscador_home_mobile #buscador- > button {
		height: 45px;
	}
	#buscador_home_mobile #buscador- > div.f_salida > div {
		border-radius: 12px 0 0 12px !important;
	}
	#buscador_home_mobile #buscador- > div.f_salida {
		width: 50% !important;
		padding-right: 0 !important;
	}
	#buscador_home_mobile #buscador- > div.f_retorno > div {
		border-left: none !important;
		border-radius: 0 12px 12px 0 !important;
	}
	#buscador_home_mobile #buscador- > div.f_retorno {
		width: 50% !important;
		padding-left: 0 !important;
		border-left: none !important;
	}
	#buscador_home_mobile #buscador- > button {
		margin: 0 15px;
		width: 100%;
	}
	#buscador_home_mobile #buscador- > div {
		padding: 0 15px !important;
	}
	#buscador_home_mobile .origen,
	#buscador_home_mobile .destino,
	#buscador_home_mobile .f_salida,
	#buscador_home_mobile .f_retorno {
		padding: 9px 15px 0 15px !important;
	}
	#buscador_home_mobile .origen > div,
	#buscador_home_mobile .destino > div,
	#buscador_home_mobile .f_salida > div,
	#buscador_home_mobile .f_retorno > div {
		padding: 8px 8px 4px 8px !important;
	}
	#buscador_home_mobile #buscador- > div {
		width: 100%;
		padding: 0;
	}
	#contacto_destino {
		@apply flex-col h-[100%] justify-start items-start py-3 px-3;
	}
	#features-landings > div {
		@apply w-[100%];
	}
	.box-formularios > div:first-child {
		@apply grid-cols-1;
	}
	.separador-izquieda::before {
		height: 30px;
	}
	.lista-destinos--- {
		@apply grid-cols-1;
	}
	#titulov1 h2 {
		@apply text-[28px];
	}
	.encomiendas__box__contenido p br {
		display: none;
	}
	#buscador.mobile .mat-expansion-panel-body {
		padding: 0 10px 16px;
	}
	#buscador.mobile .origen,
	#buscador.mobile .destino {
		width: 100%;
	}
	#buscador.mobile .f_salida,
	#buscador.mobile .f_retorno {
		width: 50%;
	}
	#buscador.mobile #buscador- > div {
		padding: 10px 25px;
		border-bottom: 1px #d9d9d9 solid !important;
	}
	#buscador.mobile #buscador- > div > div {
		padding: 0 0 0 0px !important;
	}

	#buscador.mobile .origen,
	#buscador.mobile .f_salida {
		border-right: none !important;
	}
	#dropdownContentBucadorMobile > div {
		@apply px-0 py-4;
	}
	#dropdownContentBucadorMobile {
		@apply px-[5px];
	}
}
@media (max-width: 529px) {
	.responsive-div-promociones.tres,
	.responsive-div-promociones.dos {
		display: none;
	}
	.responsive-div-promociones > div {
		height: calc(464px * (100vw / 529px)) !important; /* Ajusta según el ancho base */
	}
	#promociones-skeletor {
		@apply grid-cols-1;
	}
}
@media (max-width: 500px) {
	.items_footer li {
		text-align: center;
	}
	.items_footer h2 {
		text-align: center;
	}
	.items_footer {
		@apply grid-cols-1;
		padding-left: 0;
		border-left: 0;
	}
}

html,
body {
	height: 100%;
}
body {
	margin: 0;
	font-family: Roboto, 'Helvetica Neue', sans-serif;
}
